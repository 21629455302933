const globalSearchFormData = {
    text: {
        description: '',
        elementType: 'globalSearch',
        elementConfig: {
            type: 'text',
            placeholder: 'search'
        },
        value: '',
        selectedValue: 'all',
        validation: {
            required: false,
            isGlobalSearch: true
        },
        valid: false,
        touched: false
    }
};

export default globalSearchFormData;