const URL_TYPE = {
    STAGING: 'staging',
    PROD: 'prod',
    PROD_PUBLIC: 'prod-public',
    LOCAL_HOST: 'localhost'
};

const APP_URLS = {
    COM: {
        STAGING: {
            HOST: 'staging.theomicon.com',
            EP: 'https://staging.theomicon.com',
            WS: 'wss://staging.theomicon.com'
        },
        PROD: {
            HOST: 'www.theomicon.com',
            HOSTS: ['www.theomicon.com', 'theomicon.com'],
            EP: 'https://www.theomicon.com',
            WS: 'wss://www.theomicon.com'
        }
    },
    EDU: {
        STAGING: {
            EP: 'https://staging.theomicon.ucsf.edu',
            WS: 'wss://staging.theomicon.ucsf.edu'
        },
        PROD: {
            EP: 'https://theomicon.ucsf.edu',
            WS: 'wss://theomicon.ucsf.edu'
        }
    }
}

const ENV_VARS = {
    STAGING: 'Staging',
    PRODUCTION: 'Production'
};

const FETCH_ENDPOINT_URLS = (env) => {
    let endPoint = APP_URLS.EDU.PROD.EP, // 'https://theomicon.ucsf.edu',
        wsPoint = APP_URLS.EDU.PROD.WS; // 'wss://theomicon.ucsf.edu';
    if (env === URL_TYPE.STAGING) {
        endPoint = APP_URLS.EDU.STAGING.EP; // 'https://staging.theomicon.ucsf.edu';
        wsPoint = APP_URLS.EDU.STAGING.WS; // 'wss://staging.theomicon.ucsf.edu';
        if (window.location.host === APP_URLS.COM.STAGING.HOST) { // 'staging.theomicon.com') {
            endPoint = APP_URLS.COM.STAGING.EP; // 'https://staging.theomicon.com';
            wsPoint = APP_URLS.COM.STAGING.WS; // 'wss://staging.theomicon.com';
        }
    } else if (env === URL_TYPE.LOCAL_HOST) {
        endPoint = 'http://localhost:8000';
        wsPoint = 'ws://localhost:8000';
    } else {
        if (APP_URLS.COM.PROD.HOSTS.indexOf(window.location.host) >= 0) {
            endPoint = APP_URLS.COM.PROD.EP; // 'https://www.theomicon.com';
            wsPoint = APP_URLS.COM.PROD.WS; // 'wss://www.theomicon.com';
        }
    }
    return { endPoint, wsPoint };
}

const getURLS = (env = URL_TYPE.STAGING) => {
    let { endPoint, wsPoint } = FETCH_ENDPOINT_URLS(env);
    return {
        END_POINT: endPoint,
        WS_POINT: wsPoint,
        URL_TYPE: env
    }
}

export const CONST = {
    ...getURLS(URL_TYPE.PROD_PUBLIC),
    environmentalVariables: ENV_VARS.PRODUCTION,
    leftPanelOpenTimeForNetworkRender: 250,
    networkMaxScale: 0.7525,
    advancedSearchResultsMaxLen: 8,
    SHIBBOLETH: 'Shibboleth.sso',
    OMICON_SEARCH_FILTERS: 'https://docs.google.com/spreadsheets/d/1kLycqoQAKMgyJcb8orQ6x4Oh39sYg22G',
    OMICON_SECRET_PASS: `OmiconWebAppUcsf`,
    OMICON_VERSION: '1.0.8.33'
};
